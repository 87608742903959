.disclaimer {
  position: unset;
  margin: 2em 2em 0em 2em;
}

.container {
  padding: 2em 0em 0em 2em;
  background-color: #f0f2f5;
  overflow-y: scroll;
}

.passwordContent {
  padding: 2em;
  background-color: white;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 2em;
}

.logo {
  margin-bottom: 4em;
  height: 100px;
  width: 100px;
}

.homeIcon {
  color: var(--light-gray);
}

.submitButton {
  width: 100%;
}

.passwordChangeForm {
  width: 100%;
}

.fieldItem {
  width: 300px;
}