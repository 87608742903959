.layoutContainer {
  background: white;
  height: 100%;
}

/* .menuContainer {
  height: 100%;
} */

.extendedLogoContainer {
  background: white;
  width: 100%;
  padding: 1.5em 2em 1.5em 1.5em;
}